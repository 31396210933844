"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "idlFactory", {
  enumerable: true,
  get: function () {
    return _routerDid.idlFactory;
  }
});
exports.routerCanister = exports.router = void 0;
var _agent = require("@dfinity/agent");
var _routerDid = require("./declarations/router/router.did.js");
// Imports and re-exports candid interface

const defaultRouter = "kbzti-laaaa-aaaai-qe2ma-cai";

// CANISTER_ID is replaced by webpack based on node environment
const routerCanister = (canisterId, options) => {
  canisterId = canisterId || defaultRouter;
  const agent = new _agent.HttpAgent({
    host: process.env.REACT_APP_IC_GATEWAY || "https://ic0.app",
    ...(options ? options.agentOptions : {})
  });

  // Fetch root key for certificate validation during development
  if (process.env.REACT_APP_LOCAL_BACKEND) {
    agent.fetchRootKey().catch(err => {
      console.warn("Unable to fetch root key. Check to ensure that your local replica is running");
      console.error(err);
    });
  }

  // Creates an actor with using the candid interface and the HttpAgent
  let actor = _agent.Actor.createActor(_routerDid.idlFactory, {
    agent,
    canisterId: canisterId.toText ? canisterId.toText() : canisterId,
    ...(options ? options.actorOptions : {})
  });
  return {
    router: actor,
    agent
  };
};
exports.routerCanister = routerCanister;
const router = {};
exports.router = router;
router.setOptions = (canisterId, options) => {
  canisterId = canisterId || defaultRouter;
  let x = routerCanister(canisterId, options);
  for (let key in x.router) {
    router[key] = function () {
      return x.router[key](...arguments);
    };
  }
};