"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.canisterId = void 0;
Object.defineProperty(exports, "idlFactory", {
  enumerable: true,
  get: function () {
    return _ledgerDid.idlFactory;
  }
});
exports.ledgerCanister = void 0;
var _agent = require("@dfinity/agent");
var _ledgerDid = require("./declarations/ledger/ledger.did.js");
// Imports and re-exports candid interface

const canisterId = "ryjl3-tyaaa-aaaaa-aaaba-cai"; //process.env.NFT_CANISTER_ID;
exports.canisterId = canisterId;
const ledgerCanister = options => {
  const agent = new _agent.HttpAgent({
    host: process.env.REACT_APP_IC_GATEWAY || "https://ic0.app",
    ...(options ? options.agentOptions : {})
  });

  // Fetch root key for certificate validation during development
  if (process.env.REACT_APP_LOCAL_BACKEND) {
    agent.fetchRootKey().catch(err => {
      console.warn("Unable to fetch root key. Check to ensure that your local replica is running");
      console.error(err);
    });
  }

  // Creates an actor with using the candid interface and the HttpAgent
  return _agent.Actor.createActor(_ledgerDid.idlFactory, {
    agent,
    canisterId,
    ...(options ? options.actorOptions : {})
  });
};
exports.ledgerCanister = ledgerCanister;