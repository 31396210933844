"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PrincipalToSlot = exports.PrincipalToIdx = exports.PrincipalFromSlot = exports.PrincipalFromIdx = void 0;
var _principal = require("@dfinity/principal");
var _data = require("./data");
const PrincipalFromIdx = idx => {
  return _principal.Principal.fromUint8Array([...(0, _data.numberToBytesArray)(idx, 8), 1, 1]);
};
exports.PrincipalFromIdx = PrincipalFromIdx;
const PrincipalFromSlot = (space, idx) => {
  let start = Number(space[0][0]);
  return PrincipalFromIdx(start + Number(idx));
};
exports.PrincipalFromSlot = PrincipalFromSlot;
const PrincipalToIdx = p => {
  let a = [...p.toUint8Array()].slice(0, -2);
  let idx = (0, _data.bytesArrayToNumber)(a);
  return idx;
};
exports.PrincipalToIdx = PrincipalToIdx;
const PrincipalToSlot = (space, p) => {
  let idx = PrincipalToIdx(p);
  let start = Number(space[0][0]);
  return idx - start;
};

// let can = "pfrbz-maaaa-aaaai-qcmiq-cai";

// console.log(can, PrincipalToIdx(Principal.fromText(can)));
exports.PrincipalToSlot = PrincipalToSlot;