"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "idlFactory", {
  enumerable: true,
  get: function () {
    return _pwrDid.idlFactory;
  }
});
exports.pwrCanister = void 0;
var _agent = require("@dfinity/agent");
var _pwrDid = require("./declarations/pwr/pwr.did.js");
// Imports and re-exports candid interface

const pwrCanister = (canisterId, options) => {
  const agent = new _agent.HttpAgent({
    host: process.env.REACT_APP_IC_GATEWAY || "https://ic0.app",
    ...(options ? options.agentOptions : {})
  });
  // Fetch root key for certificate validation during development
  if (process.env.REACT_APP_LOCAL_BACKEND) {
    agent.fetchRootKey().catch(err => {
      console.warn("Unable to fetch root key. Check to ensure that your local replica is running");
      console.error(err);
    });
  }

  // Creates an actor with using the candid interface and the HttpAgent
  return _agent.Actor.createActor(_pwrDid.idlFactory, {
    agent,
    canisterId: canisterId.toText ? canisterId.toText() : canisterId,
    ...(options ? options.actorOptions : {})
  });
};
exports.pwrCanister = pwrCanister;