"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.priceStorage = exports.priceOps = exports.STORAGE_KB_PER_MIN = exports.QUALITY_PRICE = exports.FULLY_CHARGED_MINUTES = exports.FULLY_CHARGED_MESSAGES = exports.AVG_MESSAGE_COST = void 0;
const QUALITY_PRICE = 1000n; // max quality price per min
exports.QUALITY_PRICE = QUALITY_PRICE;
const STORAGE_KB_PER_MIN = 8n; // prices are in cycles
exports.STORAGE_KB_PER_MIN = STORAGE_KB_PER_MIN;
const AVG_MESSAGE_COST = 4000000n; // prices are in cycles
exports.AVG_MESSAGE_COST = AVG_MESSAGE_COST;
const FULLY_CHARGED_MINUTES = 8409600n; //(16 * 365 * 24 * 60) 16 years
exports.FULLY_CHARGED_MINUTES = FULLY_CHARGED_MINUTES;
const FULLY_CHARGED_MESSAGES = 5840n; // 1 message per day
exports.FULLY_CHARGED_MESSAGES = FULLY_CHARGED_MESSAGES;
const priceStorage = _ref => {
  let {
    custom,
    content,
    thumb,
    quality,
    ttl
  } = _ref;
  // WARNING: Has to mirror motoko calulcations in nft_interface

  let cost_per_min = STORAGE_KB_PER_MIN * 100n;
  cost_per_min += BigInt(custom) * STORAGE_KB_PER_MIN / 1024n;
  if (content && content.internal) cost_per_min += BigInt(content.internal.size) * STORAGE_KB_PER_MIN / 1024n;
  if (thumb && thumb.internal) cost_per_min += BigInt(thumb.internal.size) * STORAGE_KB_PER_MIN / 1024n;
  cost_per_min += BigInt(Math.pow(quality, 3)) * QUALITY_PRICE;
  return 2n * cost_per_min * (ttl ? BigInt(ttl) : FULLY_CHARGED_MINUTES);
};
exports.priceStorage = priceStorage;
const priceOps = _ref2 => {
  let {
    ttl
  } = _ref2;
  return 2n * (ttl ? AVG_MESSAGE_COST * 100n + BigInt(ttl) * AVG_MESSAGE_COST / 60n / 24n : FULLY_CHARGED_MESSAGES * AVG_MESSAGE_COST);
};
exports.priceOps = priceOps;