"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mintFormValidate = void 0;
exports.validateAttributeChange = validateAttributeChange;
exports.validateAttributeName = validateAttributeName;
exports.validateContentInternal = validateContentInternal;
exports.validateCooldown = validateCooldown;
exports.validateDescription = validateDescription;
exports.validateDescriptionOrNone = validateDescriptionOrNone;
exports.validateDomain = validateDomain;
exports.validateExtensionCanister = validateExtensionCanister;
exports.validateExternal = validateExternal;
exports.validateExternalType = validateExternalType;
exports.validateHoldId = validateHoldId;
exports.validateName = validateName;
exports.validateTagName = validateTagName;
exports.validateThumbInternal = validateThumbInternal;
exports.validateUseId = validateUseId;
var _principal = require("@dfinity/principal");
const mintFormValidate = values => {
  const errors = {};
  if (!values.extensionCanister) if (values.content_storage == "external" || values.thumb_storage == "external" || values.use || values.hold) errors.extensionCanister = "Required if using external content and effects";
  if (values.secret) if (values.content_storage == "external") errors.secret = "Can't be secret if content is external";
  return errors;
};
exports.mintFormValidate = mintFormValidate;
function validateDomain(value) {
  if (!value) return null;
  return !(value.length > 2 && value.length < 64) ? "Must be between 3 and 64 characters" : null;
}
function validateName(value) {
  if (!value) return null;
  return !(value.length > 2 && value.length < 96) ? "Must be between 3 and 96 characters" : null;
}
function validateExtensionCanister(v) {
  if (!v) return;
  try {
    _principal.Principal.fromText(v);
    return;
  } catch (e) {
    return "Invalid principal";
  }
}
function validateHoldId(v) {
  if (!v) return "Required";
  if (v.search(/[^a-z]+/) !== -1) return "Can only contain lowercase characters without spaces";
}
function validateUseId(v) {
  if (!v) return "Required";
  if (v.search(/[^a-z]+/) !== -1) return "Can only contain lowercase characters without spaces";
}
function validateDescription(val) {
  if (typeof val !== "string") return null;
  if (val.length < 10 || val.length > 256) return "Must be between 10 and 256 characters";
}
function validateThumbInternal(val) {
  if (!val) return "Thumbnail is required";
  if (val.size > 1024 * 128) return "Maximum file size is 128 KB";
}
function validateContentInternal(val) {
  if (!val) return;
  if (val.size > 1024 * 1024 * 1) return "Maximum file size is 1 MB";
}
function validateExternal(val) {
  if (!val) return;
  if (isNaN(val)) return "If specified, it must be 32 bit natural number";
}
function validateExternalType(val) {
  if (!val) return "Must be specified";
  if (val.length < 3) return "Not a valid content type";
}
function validateDescriptionOrNone(val) {
  if (typeof val !== "string") return null;
  if (val.trim().length === 0) return null;
  if (val.length < 10 || val.length > 256) return "Must be none or between 10 and 256 characters";
}
function validateCooldown(val) {
  if (val < 1) return "Has to be at least one minute";
}
function validateAttributeName(val) {
  if (typeof val !== "string") return null;
  if (val.length < 3) return "Too short";
  if (val.length > 24) return "Too long";
}
function validateTagName(val) {
  if (typeof val !== "string") return null;
  if (val.length < 3) return "Too short";
  if (val.length > 24) return "Too long";
}
function validateAttributeChange(val) {
  if (parseInt(val, 10) === 0) return "Can't be zero";
}