"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ArrayToText = ArrayToText;
exports.TextToArray = TextToArray;
exports.TextToSlot = TextToSlot;
exports.anvToE = anvToE;
exports.createBigIntDecimal = createBigIntDecimal;
exports.e8sToIcp = e8sToIcp;
exports.e8sToPwr = e8sToPwr;
exports.eToAnv = eToAnv;
exports.icpToE8s = icpToE8s;
exports.placeDecimal = placeDecimal;
exports.pwrToE8s = pwrToE8s;
exports.removeDecimal = removeDecimal;
var _data = require("./data");
function TextToArray(x) {
  if (!x || !x.length) return null;
  return (0, _data.fromHexString)(x);
}
function ArrayToText(x) {
  return (0, _data.toHexString)(x);
}
function e8sToIcp(x) {
  if (!x) return null;
  return (Number(BigInt(x) * 10000n / 100000000n) / 10000).toFixed(4);
}
function icpToE8s(x) {
  try {
    return BigInt(Math.round(x * 100000000));
  } catch (e) {
    return 0n;
  }
}
function createBigIntDecimal(dec) {
  return BigInt("1" + "".padStart(dec, 0));
}
function placeDecimal(x, dec, precision) {
  if (!x) return null;
  if (precision > dec) precision = dec;
  let precisionTmp = createBigIntDecimal(precision);
  return (Number(BigInt(x) * precisionTmp / createBigIntDecimal(dec)) / Number(precisionTmp)).toFixed(precision);
}
function removeDecimal(x, dec) {
  try {
    return BigInt(Math.round(x * Number(createBigIntDecimal(dec))));
  } catch (e) {
    return 0n;
  }
}

// PWR
function e8sToPwr(x) {
  if (!x) return null;
  return (Number(BigInt(x) * 100n / 100000n) / 100).toFixed(2);
}
function pwrToE8s(x) {
  try {
    return BigInt(Math.round(x * 100000));
  } catch (e) {
    return 0n;
  }
}
// ANV

function eToAnv(x) {
  if (!x) return null;
  return (Number(BigInt(x) * 10000n / 100000000n) / 10000).toFixed(4);
}
function anvToE(x) {
  try {
    return BigInt(Math.round(x * 100000000));
  } catch (e) {
    return 0n;
  }
}

// export function eToAnv(x) {
//   if (!x) return null;
//   return (Number((BigInt(x) * 100n) / 100000n) / 100).toFixed(2);
// }

// export function anvToE(x) {
//   try {
//     return BigInt(Math.round(x * 100000));
//   } catch (e) {
//     return 0n;
//   }
// }

function TextToSlot(aid, range) {
  return Number(range[0]) + (0, _data.bytesArrayToNumber)((0, _data.fromHexString)(aid).slice(-4)) % (1 + Number(range[1] - range[0]));
}