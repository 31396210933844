"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.decode = decode;
exports.encode = encode;
exports.fromText = fromText;
exports.toText = toText;
var _principal = require("@dfinity/principal");
var _data = require("./data.js");
var _baseX = _interopRequireDefault(require("base-x"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var bs = (0, _baseX.default)("812345679abcdefghijkmnopqrstuvwxyz");
function encode(slot, idx) {
  // console.log("ENCODE", slot, idx);
  return [...(0, _data.numberToBytesArray)(slot, 8), ...(0, _data.numberToBytesArray)(idx, 4)];
}
function decode(p) {
  //let p = fromText(tx);
  let idx = (0, _data.bytesArrayToNumber)(p.slice(-4));
  let slot = (0, _data.bytesArrayToNumber)(p.slice(0, -4));
  return {
    slot,
    idx
  };
}
function toText(bytes) {
  return "tx" + bs.encode(new Uint8Array([...bytes]));
}
function fromText(t) {
  return [...bs.decode(t.slice(2).toLowerCase())];
}