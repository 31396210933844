"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uploadFile = exports.toHexString = exports.numberToBytesArray = exports.jsonToNat8 = exports.generateKeyHashPair = exports.fromHexString = exports.err2text = exports.encodeLink = exports.encodeArrayBuffer = exports.djb2xor = exports.decodeLink = exports.chunkBlob = exports.bytesToBase58 = exports.bytesArrayToNumber = exports.blobPrepare = exports.base58ToBytes = exports.SerializableIC = exports.BigIntToString = void 0;
var _baseX = _interopRequireDefault(require("base-x"));
var _sha = require("@dfinity/principal/lib/cjs/utils/sha224");
var _idl = require("@dfinity/candid/lib/cjs/idl");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var BASE58 = "123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz";
var bs58 = (0, _baseX.default)(BASE58);
const bytesArrayToNumber = a => {
  let n = 0;
  for (let i = a.length - 1; i >= 0; i--) {
    n += Math.pow(256, a.length - i - 1) * a[i];
  }
  return n;
};
exports.bytesArrayToNumber = bytesArrayToNumber;
const SerializableIC = x => {
  if (x === undefined || x === null) return x;
  if (typeof x === "bigint") return x.toString();
  if (ArrayBuffer.isView(x) || x instanceof ArrayBuffer) return [...x].map(y => SerializableIC(y));
  if (Array.isArray(x)) {
    return x.map(y => SerializableIC(y));
  }
  if (typeof x === "object") {
    if ("toText" in x) return x.toText();
    return Object.fromEntries(Object.keys(x).map(k => {
      return [k, SerializableIC(x[k])];
    }));
  }
  return x;
};
exports.SerializableIC = SerializableIC;
const BigIntToString = x => {
  if (typeof x === "bigint") return x.toString();
  if (Array.isArray(x)) {
    return x.map(y => BigIntToString(y));
  }
  if (typeof x === "object") return Object.fromEntries(Object.keys(x).map(k => {
    return [k, BigIntToString(x[k])];
  }));
  return x;
};
exports.BigIntToString = BigIntToString;
const numberToBytesArray = (n, size) => {
  n = Number(n);
  // size in bytes
  const a = Array(size).fill(0);
  for (let i = 0; i < size; i++) {
    a[i] = n & 255;
    n = n >>> 8;
  }
  return new Uint8Array(a.reverse());
};
exports.numberToBytesArray = numberToBytesArray;
const generateKeyHashPair = function () {
  let getRandomValues = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  let key = getRandomValues ? getRandomValues(new Uint8Array(20)) : window.crypto.getRandomValues(new Uint8Array(20));
  var hash = (0, _sha.sha224)(key);
  return {
    key,
    hash
  };
};
exports.generateKeyHashPair = generateKeyHashPair;
const decodeLink = code => {
  let buf = bs58.decode(code);
  let slot = bytesArrayToNumber(buf.slice(0, 3));
  let tokenIndex = bytesArrayToNumber(buf.slice(3, 5));
  let key = buf.slice(5);
  return {
    slot,
    tokenIndex,
    key
  };
};
exports.decodeLink = decodeLink;
const encodeLink = (slot, tokenIndex, key) => {
  let a = numberToBytesArray(slot, 3);
  let b = numberToBytesArray(tokenIndex, 2);
  let x = new Uint8Array([...a, ...b, ...key]);
  return bs58.encode(x);
};
exports.encodeLink = encodeLink;
const bytesToBase58 = bytes => {
  return bs58.encode(new Uint8Array([...bytes]));
};
exports.bytesToBase58 = bytesToBase58;
const base58ToBytes = x => {
  return [...bs58.decode(x)];
};
exports.base58ToBytes = base58ToBytes;
const encodeArrayBuffer = file => Array.from(new Uint8Array(file));
exports.encodeArrayBuffer = encodeArrayBuffer;
const jsonToNat8 = async json => {
  const bl = new Blob([JSON.stringify(json)], {
    type: "application/json"
  });
  const buffer = await bl.arrayBuffer();
  const arr = encodeArrayBuffer(buffer);
  return arr;
};
exports.jsonToNat8 = jsonToNat8;
const fromHexString = hexString => hexString.match(/.{1,2}/g).map(byte => parseInt(byte, 16));
exports.fromHexString = fromHexString;
const toHexString = bytes => bytes.reduce((str, byte) => str + byte.toString(16).padStart(2, "0"), "");

// export const toHexString = (byteArray) => {
//   return Array.from(byteArray, function (byte) {
//     return ("0" + (byte & 0xff).toString(16)).slice(-2);
//   }).join("");
// };
exports.toHexString = toHexString;
const chunkBlob = async url_or_blob => {
  let blob;
  if (typeof url_or_blob === "string") blob = await fetch(url_or_blob).then(r => r.blob());else blob = url_or_blob;
  let size = blob.size;
  let chunkSize = 1024 * 512;
  let chunks = Math.ceil(size / chunkSize);
  let r = [];
  for (let i = 0; i < chunks; i++) {
    r.push(blob.slice(i * chunkSize, (i + 1) * chunkSize));
  }
  return r;
};
exports.chunkBlob = chunkBlob;
const err2text = e => {
  if (e === null || e === undefined) return e;
  if (e instanceof Error) return e.message;
  if (typeof e === "string") return e;
  if (Object.keys(e).length === 1) {
    let key = Object.keys(e)[0];
    if (e[key] === null) return key;
    if (typeof e[key] === "string" || "toString" in e[key]) return `${key}: ${e[key]}`;
    return key;
  }
};
exports.err2text = err2text;
const blobPrepare = async chunk => Array.from(new Uint8Array(await chunk.arrayBuffer()));
exports.blobPrepare = blobPrepare;
const djb2xor = str => {
  // The normal djb2 from Text.hash is hard to do in js because overflow looses precision
  let len = str.length;
  let h = 5381;
  for (let i = 0; i < len; i++) {
    h = h * 33 ^ str.charCodeAt(i);
  }
  return h >>> 0;
};
exports.djb2xor = djb2xor;
const uploadFile = async function (nft, tokenIndex, position, chunks, subaccount) {
  let tried = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 0;
  try {
    await Promise.all(chunks.map(async (chunk, idx) => {
      return nft.upload_chunk({
        subaccount,
        position: {
          [position]: null
        },
        chunkIdx: idx,
        tokenIndex,
        data: await blobPrepare(chunk)
      });
    })).then(re => {});
  } catch (e) {
    await delay(2000 + tried * 1000);
    if (tried < 3) return await uploadFile(nft, tokenIndex, position, chunks, subaccount, tried + 1);else throw e;
  }
};
exports.uploadFile = uploadFile;
const delay = ms => new Promise((resolve, reject) => setTimeout(resolve, ms));