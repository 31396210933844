"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.calcRateRev = exports.calcRate = void 0;
var AccountIdentifier = _interopRequireWildcard(require("./accountidentifier.js"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
const calcRate = (amountDec, _ref) => {
  let {
    left,
    right,
    swap_fee
  } = _ref;
  let amount = Number(AccountIdentifier.removeDecimal(amountDec, left.decimals));
  let rate_zero = Number(left.reserve) / Number(right.reserve);
  let rate = (Number(left.reserve) + Number(amount)) / Number(right.reserve);
  let afterfee = left.decimals === 0 ? amount : amount - amount * swap_fee;
  let price_change = rate_zero / rate;
  let recieve = afterfee / rate;
  if (right.decimals === 0) {
    recieve = recieve - recieve * swap_fee;
  }
  let give = amount;
  if (right.decimals === 0) {
    let whole = Math.round(recieve);
    let leftover = recieve - whole;
    if (leftover !== 0) {
      return calcRateRev(whole, {
        left,
        right,
        swap_fee
      });
    }
  }
  let give_decimal = give;
  let recieve_decimal = recieve;
  give = left.decimals > 0 ? AccountIdentifier.placeDecimal(Math.round(give), left.decimals, 4) : give;
  recieve = right.decimals > 0 ? AccountIdentifier.placeDecimal(Math.round(recieve), right.decimals, 4) : recieve;
  return {
    rate,
    give,
    recieve,
    price_change,
    give_decimal,
    recieve_decimal
  };
};
exports.calcRate = calcRate;
const calcRateRev = (amountDec, _ref2) => {
  let {
    left,
    right,
    swap_fee
  } = _ref2;
  let recieve = Number(AccountIdentifier.removeDecimal(amountDec, right.decimals));
  let rate_zero = Number(left.reserve) / Number(right.reserve);
  let rate = Number(left.reserve) / (Number(right.reserve) - Number(recieve));
  let price_change = rate_zero / rate;
  let give = recieve * rate;
  if (left.decimals !== 0) give += give * swap_fee;else give = Math.round(give);
  if (right.decimals !== 0) recieve -= recieve * swap_fee;
  let give_decimal = Math.round(give);
  let recieve_decimal = Math.round(recieve);
  give = left.decimals > 0 ? AccountIdentifier.placeDecimal(Math.round(give), left.decimals, 4) : give;
  recieve = right.decimals > 0 ? AccountIdentifier.placeDecimal(Math.round(recieve), right.decimals, 4) : recieve;
  return {
    rate,
    give,
    recieve,
    price_change,
    give_decimal,
    recieve_decimal
  };
};
exports.calcRateRev = calcRateRev;